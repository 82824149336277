import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { Button, Card, CardHeader, Field, Input, Label, Option, Select, Spinner, Table, TableCell, TableHeader, TableHeaderCell, TableRow, Text, Toolbar, ToolbarButton } from '@fluentui/react-components';
import Spacer from '../../components/Spacer/Spacer';
import { Editor } from '@tinymce/tinymce-react';
import * as env from './../../env/env';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import Support, { Support_Department } from '../../lib/Support/Support';

export default class NewSupportTicketView extends React.Component {

  private breadcrumbItems: IBreadcrumbItem[];
  private styles: any = {};

  public constructor(props:any) {
    super(props);

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => window.location.assign("/dashboard") },
      { text: 'Customer Support', key: 'customer_support'},
      { text: 'Create Support Ticket', key: 'customer_support', isCurrentItem: true},
    ];

    this.state = {
      is_loading: true,
      is_creating: false,
      support_departments: [] as Array<Support_Department>
    };

    this.styles = {
      form_wrapper: {
        display: 'flex',
        columnGap: 25
      }
    };

    // load the required data for the form...
    this.load_data();
  }

  public async load_data(): Promise<void> {
    try {
      const supportDepartments: Array<Support_Department> = await Support.get_support_departments();
      
      // release the is loading state...
      this.setState({
        support_departments: supportDepartments,
        is_loading: false,

        title:         "",
        department_id: 0,
        message:       ""
      });

    }
    catch(error) {
      alert('Could not load form data...');
    }
  }

  private create_support_ticket = async (): Promise<void> => {
    try {
      this.setState({
        is_creating: true
      });

      const state = this.state as any;
      await Support.create_support_ticket({
        title:         state.title,
        department_id: state.department_id,
        message:       state.message,
        urgency:       'normal'
      });

      // redirect the user back to the main support screen...
      (this.props as any).navigate("/support");

    }
    catch(error) {
      alert('Could not create support ticket...');
    }
  }

  public render() {
    const state: any = this.state as any;

    return (
      <SafeArea>
        <div style={{width: "100%"}}>
          <Breadcrumb
            style={{marginLeft: -6}}
            items={this.breadcrumbItems}
            maxDisplayedItems={5}
            ariaLabel="Breadcrumb with static width"
            overflowAriaLabel="More links"
          />
          <h1>Create Support Ticket</h1>
        </div>

        <Spacer size='25px' />

        <div style={{maxWidth: 1000, marginLeft: 1}}>
          <Card>
            <CardHeader 
              header={<Text weight="semibold">Create New Support Ticket</Text>}
              description={
                <></>
              }
              action={
                <></>
              }
            />

          <Spacer size='10px' />

          {state.is_loading? (
            <div>
              <ViewLoader height='100px' message="Loading form. Please wait.." />
            </div>

          ) : (
            <div style={{display: 'flex', gap: 15, flexDirection: 'column'}}>
              <div style={{width: 200}}>
                <Field label={"Support Department"}>
                  <Select
                    disabled={state.is_creating}
                    value={state.department_id}
                    onChange={(event) => {
                      this.setState({
                        department_id: event.target.value
                      });
                    }}
                  >
                    {state.support_departments.map((item: Support_Department) => {
                      return (
                        <option value={item.department_id}>{item.display_name}</option>
                      );
                    })}
                  </Select>
                </Field>
              </div>

              <div style={this.styles.form_wrapper}>
                <div style={{width: 600}}>
                <Field label={"Subject"}>
                  <Input
                    value={state.title}
                    size='medium'
                    disabled={state.is_creating}
                    // onChange={handleChange}
                    placeholder="Enter the support ticket subject here..."
                    required
                    onChange={(event) => {
                      this.setState({
                        title: event.target.value
                      });
                    }}
                  />
                </Field>
                </div>
                <div style={{width: 200}}>
                  <Field label={"Priority"}>
                    <Select disabled={state.is_creating} size='medium' onChange={(event) => {
             
                    }}>
                      <option >Normal</option>
                    </Select>
                  </Field>
                </div>
              </div>
              <div style={{width: "100%"}}>
                <Field label={"Message"}>
                  <Editor
                    disabled={state.is_creating}
                    value={state.message}
                    init={{
                      apiKey: env.default.TINYMCE_API_KEY,
                      menubar: false,
                    }}
                    apiKey={env.default.TINYMCE_API_KEY}
                    onEditorChange={(content) => {  
                      this.setState({
                        message: content
                      });
                    }}
                  />
                </Field>
              </div>
              
              <div style={{display: "flex", gap: 10, alignSelf: 'end'}}>
                <Button disabled={state.is_creating} onClick={() => (this.props as any).navigate("/support")} style={{width: 120}}>Cancel</Button>
                <Button disabled={state.is_creating} onClick={this.create_support_ticket} style={{width: 150, alignSelf: "end"}} appearance='primary'>
                    {state.is_creating? (
                      <Spinner size='tiny' />
                    ) : (
                      "Open Ticket"
                    )}
                </Button>
              </div>
            </div>
          )}

          </Card>
        </div>
      </SafeArea>
    );
  }

}