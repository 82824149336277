import store from '../../redux/ReduxStore';
import Log from '../Log/Log';
import * as env from './../../env/env';
import axios, { AxiosError } from 'axios';
import Authentication_Service from './../Authentication/AuthenticationService';

/**
 * Interface representing the status of a backend request performance.
 */
export interface Backend_Request_Perform_Status {
  has_performed: boolean,
  is_success: boolean,
  response_data: any
};

/**
 * Interface defining the arguments required for making a backend request.
 */
export interface Backend_Request_Arguments {
  endpoint: string,
  authenticated?: boolean,
  absoluteUri?: boolean, 
  absoluteServer?: boolean,
  requestBody: any,
  verbose?: boolean,

  critical?: boolean
}

/**
 * Class for performing backend requests with optional authentication.
 */
export default class Backend_Request {
  private isAuthenticatedRequest: boolean = true;
  private endpointRequestUri: string = "";

  private requestSatus: boolean = false;
  private responseBody: any = {};
  private requestBody: any = {};

  private verbose: boolean = false;
  private maxRetries: number = 3;
  private retryInterval: number = 550; // milliseconds

  // is the request critical for app operation... we will halt if the request fails...
  private critical: boolean = false;
  private hasAttemptedTokenRefresh: boolean = false;

  /**
   * Constructor for the Backend_Request class.
   * 
   * @param requestArguments - Configuration options for the backend request.
   */
  public constructor(requestArguments: Backend_Request_Arguments) {
    this.endpointRequestUri = requestArguments.endpoint;
    this.requestBody = requestArguments.requestBody;

    this.endpointRequestUri = env.default.API_REMOTE_SERVER + this.endpointRequestUri;

    if (requestArguments.verbose) {
      this.verbose = requestArguments.verbose;
    }
  }

  /**
   * Check if the request is authenticated.
   * 
   * @returns boolean - True if the request is authenticated, false otherwise.
   */
  public get_is_authenticated_request(): boolean {
    return this.isAuthenticatedRequest;
  }

  /**
   * Set whether the request requires authentication.
   * 
   * @param authenticatedRequest - Boolean indicating if the request should be authenticated.
   */
  public set_is_authenticated_request(authenticatedRequest: boolean): void {
    this.isAuthenticatedRequest = authenticatedRequest;
  }

    /**
   * Type guard to check if an error is an AxiosError.
   * 
   * @param error - The error to check.
   * @returns boolean - True if the error is an AxiosError, false otherwise.
   */
    private is_axios_error(error: any): error is AxiosError {
      return (error as AxiosError).isAxiosError !== undefined;
    }

  /**
   * Perform the backend request.
   * 
   * @returns Promise<Backend_Request_Perform_Status> - A promise that resolves to the status of the request.
   */
  public async perform(): Promise<Backend_Request_Perform_Status> {
    try {
      // if the token is refreshing, then we will await for it to be completed.
      const reduxState = store.getState();
      if(reduxState.global_auth.is_refreshing) {

        // subscribe to changes and await for a change in the refreshing state, then execute.
        // this is also designed to block the request, and keep the thread running until the refresh is completed.
        return new Promise<Backend_Request_Perform_Status>((resolve) => {
          store.subscribe( async () => {
            const updatedReduxState = store.getState();
            if(!updatedReduxState.global_auth.is_refreshing) {
              return await this.perform();
            }
          });
        });

      }
      else {
        // the state is not refreshing, so we will continue with the request execution.
        if (this.isAuthenticatedRequest) {
          if (typeof reduxState.global_auth.auth_data.access_token !== 'undefined') {
            const accessToken = reduxState.global_auth.auth_data.access_token;
            const requestBody = { ...this.requestBody, access_token: accessToken };

            for (let attempt = 0; attempt < this.maxRetries; attempt++) {
              try {
                const response = await axios.post(
                  this.endpointRequestUri,
                  requestBody,
                  {
                    headers: { 
                      'Content-Type': 'application/x-www-form-urlencoded',
                      // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    }
                  }
                );
          
                const responseData = response.data;
                
                if (this.verbose) {
                  Log.dev_log(responseData);
                }
          
                this.requestSatus = true;
          
                return {
                  has_performed: true,
                  is_success: true,
                  response_data: responseData.response_data
                };
          
              } catch (error) {
                if (this.is_axios_error(error) && error.response) {
                  const status = error.response.status;
          
                  // Handle 401 Unauthorized
                  if (status === 401) {
                    try {
                      const newToken = await Authentication_Service.refresh_authentication_token();
                      this.requestBody.Authorization = `Bearer ${newToken}`;
                      continue; // Retry request with new token
                    } catch (refreshError) {
                      console.error("Token refresh failed:", refreshError);
                      break; // Exit loop if refresh fails
                    }
                  }
          
                  // Handle retry-able errors (e.g., network issues, 5xx errors)
                  if (attempt < this.maxRetries - 1 && (status >= 500 || status === 429)) {
                    await new Promise(res => setTimeout(res, this.retryInterval));
                    continue;
                  }
                }
          
                // Log non-retryable errors or max retry reached
                Log.dev_error(error);
                this.requestSatus = false;
                return { has_performed: false, is_success: false, response_data: null };
              }
            }
            
            // for (let attempt = 0; attempt < this.maxRetries; attempt++) {
            //   // log for dev use...
            //   if(attempt > 1) {
            //     Log.dev_log("--> Re-Attempting backend API request after " + this.retryInterval +  "ms");
            //   }

            //   try {
            //     const response = await axios.post(
            //       this.endpointRequestUri, requestBody,
            //       {
            //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            //       }
            //     );

            //     const responseData = response.data;
            //     if (this.verbose) {
            //       Log.dev_log(responseData);
            //     }
            //     this.requestSatus = true;

            //     return {
            //       has_performed: true,
            //       is_success: true,
            //       response_data: responseData.response_data
            //     };
            //   } catch (error) {
            //     if (this.is_axios_error(error) && error.response && error.response.status === 401 && attempt < this.maxRetries - 1) {
            //       await new Promise(res => setTimeout(res, this.retryInterval));
            //       continue;
            //     } else {
            //       Log.dev_error(error);
            //       this.requestSatus = false;

            //       return {
            //         has_performed: false,
            //         is_success: false,
            //         response_data: null
            //       };
            //     }
            //   }
            // }

            // are we critical? If so then we will refresh the token to try and make this work...
            if(this.critical && !this.get_status() && !this.hasAttemptedTokenRefresh) {
              // attempt to refresh the auth token, then try the request again one last time...
              Log.dev_log("--> Refreshing token for critical API request to complete...");

            }

            // default data return type...
            return {
              has_performed: false,
              is_success: false,
              response_data: null
            };
          } 

          /**
           * Handle unauthrnticated requests....
           */
          else {
            const response = await axios.post(
              this.endpointRequestUri, this.requestBody,
              {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
              }
            );

            const responseData = response.data;
            if (this.verbose) {
              Log.dev_log(responseData);
            }
            this.requestSatus = true;

            return {
              has_performed: true,
              is_success: true,
              response_data: responseData.response_data
            };
          }
        } else {
          return {
            has_performed: true,
            is_success: true,
            response_data: null
          };
        }

      }
    } catch (error) {
      Log.dev_warning(error);

      return {
        has_performed: true,
        is_success: false,
        response_data: null
      };
    }
  }

  /**
   * Get the status of the request.
   * 
   * @returns boolean - The status of the request.
   */
  public get_status(): boolean {
    return this.requestSatus;
  }

  /**
   * Get the response body from the request.
   * 
   * @returns any - The response body.
   */
  public get_response() {
    return this.responseBody;
  }
}
