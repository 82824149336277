export default {
  /**
   * The environment type....
   * 
   * Expected values:
   * - "development" => The environment is development.
   * - "production"  => The environment is production.
   * 
   * This will effect things like the logging and session data...
   * 
   */
  // "type": "development",
  "type": "production",

  "TINYMCE_API_KEY": "tbh3d6cisw6hf41f8hyuju8iasdi4z2u3tvw01skzkys6lx1",

  /**
   * STRIPE publishable API key for use on this public frontend application...
   * 
   */
  // "STRIPE_PUBLIC_KEY": "pk_test_51OS4OTHeWCB40Rj3Qt53jwH39ZSVhGEHOn7wDluSaefY3cMXX4JbNrFYXkFaEPtZiu8Pkf67QPndDCuo7EiV9TkA00MaLMFXKY",
  "STRIPE_PUBLIC_KEY": "pk_live_51OS4OTHeWCB40Rj3lOFeJMCNZO9q2DeSY8BmX2MM8RrAHJM2e4ovQp5x5tMK4RF0qnMRR06AqEMgRRFad36wFJl100FkDPr9Ve",
  
  /**
   * Main server URL for 121 Digital Core API
   */
  // "API_REMOTE_SERVER": "http://localhost:8888/121Domains/api.php/",
  "API_REMOTE_SERVER": "https://api.121domains.co.uk/api.php/",

  /**
   * Keyaloak server environment...
   * 
   */
  "KEYCLOAK_URL":      "https://sso.121domains.co.uk",

  // "KEYCLOAK_REALM":     "121_digital_dev",
  // "KEYCLOAK_CLIENT_ID": "121_digital_console"

  /**
   * Prod values...
   */
  "KEYCLOAK_REALM":     "121_digital",
  "KEYCLOAK_CLIENT_ID": "121_digital_console"
  
}