import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import BlockedFeature from '../../components/BlockedFeature/BlockedFeature';
// import { Breadcrumb } from '@fluentui/react-components';
import { Breadcrumb, CommandBar, IBreadcrumbItem } from '@fluentui/react';
import Spacer from '../../components/Spacer/Spacer';
import { Badge, Button, Card, CardHeader, Table, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Text, Toolbar, ToolbarButton } from '@fluentui/react-components';
import Support, { Support_Ticket } from '../../lib/Support/Support';
import ViewLoader from '../../components/ViewLoader/ViewLoader';

import {CheckmarkCircleRegular} from '@fluentui/react-icons';
import {ChatBubblesQuestionRegular} from '@fluentui/react-icons';
import Global_Error from '../../lib/GlobalError/GlobalError';

export default class SupportView extends React.Component {

  private breadcrumbItems: IBreadcrumbItem[];
  private styles: any = {};

  public constructor(props: any) {
    super(props);

    this.state = {
      is_loading: true
    };

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate("/") },
      { text: 'Customer Support', key: 'customer_support', isCurrentItem: true },
    ];

    // define view styles...
    this.styles = {
    commandBar: {
      borderTopStyle: "solid",
      width: "100%"
    }
  };

    this.load_data();
  }

  public load_data = async () => {
    try {
      const supportTickets: Array<Support_Ticket> = await Support.get_all_tickets();
      this.setState({
        support_tickets: supportTickets,
        is_loading: false
      });

    }
    catch(error) {
      const globalError = new Global_Error();
      globalError.display_user_visible_error({
        error_title: "Cannot Load Support Tickets",
        error_content: "There seems to be an issue loading your support tickets. Please refresh the page and try again or contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
      });
    }
  }

  public render() {
    const state: any = this.state as any;

    return (
      <SafeArea>
        <div style={{width: "100%"}}>
          <Breadcrumb
            style={{marginLeft: -6}}
            items={this.breadcrumbItems}
            maxDisplayedItems={5}
            ariaLabel="Breadcrumb with static width"
            overflowAriaLabel="More links"
          />
          <h1>Support Overview</h1>
        </div>

        <Spacer size='25px'></Spacer>

        <div style={{maxWidth: 1200, marginLeft: 1}}>
          <Card>
            <CardHeader 
              header={<Text weight="semibold">Your Support Tickets</Text>}
              description={
                <></>
              }
              action={
                <Button size='medium' onClick={() => (this.props as any).navigate("/support/create-ticket")} appearance='primary'>
                  New
                </Button>
              }
            />

            {state.is_loading? (
              <ViewLoader height='180px' message="Loading Support Tickets. Please wait..." />
            ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell style={{paddingLeft: 0}}>Ticket ID</TableHeaderCell>
                  <TableHeaderCell style={{width: "30%"}}>Subject</TableHeaderCell>
                  <TableHeaderCell style={{width: 180}}>Date Created</TableHeaderCell>
                  <TableHeaderCell>Department</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Next Action</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHeader>

              {state.support_tickets.map((ticket: Support_Ticket) => {
                return (
                  <TableRow>
                    <TableCell style={{ paddingLeft: 0, display: "flex", alignItems: "center", textAlign: "left", gap: 8 }}>
                      {(ticket.status == "ticket_open")? (
                        <ChatBubblesQuestionRegular fontSize={20} />
                      ) : (
                        <CheckmarkCircleRegular fontSize={20} />
                      )}
                      {ticket.ticket_id}
                    </TableCell>
                    {/* <TableCell style={{ paddingLeft: 0, display: "flex", alignItems: "center", textAlign: "left", gap: 8 }}> */}
                    <TableCell style={{width: "30%"}}>
                      {ticket.title}
                    </TableCell>
                    <TableCell style={{width: 180}}>{ticket.date}</TableCell>
                    <TableCell>{ticket.department_name}</TableCell>
                    <TableCell>
                      {(ticket.status == "ticket_open")? (
                        <Badge>Ticket Open</Badge>
                      ) : (
                        <Badge color='success'>Ticket Resolved</Badge>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          (this.props as any).navigate('/support/view-ticket/' + ticket.ticket_id);
                        }}
                        style={{float: 'right'}}>View Ticket</Button>
                    </TableCell>
                  </TableRow>
                );
              })}

            </Table>
            )}
          </Card>
        </div>
      </SafeArea>
    );
  }

}