import { combineReducers, createStore } from 'redux';
import rootReducer from './AccountReducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const accountReduxPersistConfig = {
  key: 'account',
  storage: storage, // Use AsyncStorage for React we b
};

const persistedReducer = persistReducer(accountReduxPersistConfig, rootReducer);

const accountStore = createStore(persistedReducer);
export default accountStore;
export const persistor = persistStore(accountStore);

export const await_account_persistor_rehydration = new Promise((resolve, reject) => {
  persistor.subscribe(() => {
    if (accountStore.getState()._persist.rehydrated) {
      resolve(true);
    }
  });
});

