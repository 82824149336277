import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { Card, CardHeader } from '@fluentui/react-card';
import { Button, Caption1, Table, TableBody, TableCell, TableRow, Text } from '@fluentui/react-components';
import Spacer from '../../components/Spacer/Spacer';
import accountStore from '../../redux/Account/AccountRedux';

export default class AccountView extends React.Component {

  private styles: any = {};
  private breadcrumbItems: IBreadcrumbItem[];

  public constructor(props: any) {
    super(props);

    // define breadcrumb items...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate("/dashboard") },
      { text: 'Your Account', key: 'manage_account', isCurrentItem: true },
    ];

    // define base state.
    const reduxState = accountStore.getState();
    this.state = {
      account: reduxState.account
    };

    // define styles for view...
    this.styles = {
      breadcrumb_wrapper: {
        marginLeft: -7
      },

      cards_wrapper: {
        display: "flex",
        flexDirection: "row",
        gap: 25
      },

      card_wrapper: {
        width: 500,

        // fix for fluentUI card border issue...
        marginLeft: 1
      }
    };
  }

  public render() {
    const state = this.state as any;

    return (
      <SafeArea>
        <div style={this.styles.breadcrumb_wrapper}>
          <Breadcrumb
            items={this.breadcrumbItems}
            maxDisplayedItems={5}
            ariaLabel="Breadcrumb with static width"
            overflowAriaLabel="More links"
          />
        </div>
        <h1>Manage Your Account</h1>

        <Spacer size='25px' />

        <div style={this.styles.cards_wrapper}>
          <div style={this.styles.card_wrapper}>
            <section>
              <Card>
                <CardHeader
                  header={<Text weight="semibold">Basic Organisation Overview</Text>}
                  description={
                    <Caption1></Caption1>
                  }
                />

                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>Organisation</b>
                      </TableCell>
                      <TableCell>
                        {state.account.organisation_name}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <b>Primary Contact</b>
                      </TableCell>
                      <TableCell>
                        {state.account.first_name} {state.account.last_name}
                      </TableCell>
                    </TableRow>


                    <TableRow>
                      <TableCell>
                        <b>Email Address</b>
                      </TableCell>
                      <TableCell>
                        {state.account.email_address}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <br />

                {/* <Button  
                  style={{width: 150}}
                  onClick={() => {
            
                  }}
                >Update Details</Button> */}
              </Card>
            </section>
          </div>

          <div style={this.styles.card_wrapper}>
            <section>
              <Card>
                <CardHeader
                  header={<Text weight="semibold">Your Account Profile Overview</Text>}
                  description={
                    <Caption1></Caption1>
                  }
                />

                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>Full Name</b>
                      </TableCell>
                      <TableCell>
                        {state.account.first_name} {state.account.last_name}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <b>Email Address</b>
                      </TableCell>
                      <TableCell>
                        {state.account.email_address}
                      </TableCell>
                    </TableRow>


                    <TableRow>
                      <TableCell>
                        <b>Phone Number</b>
                      </TableCell>
                      <TableCell>
                        {state.account.phone_number}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <br />

                {/* <Button  
                  style={{width: 150}}
                  onClick={() => {
            
                  }}
                >Update Details</Button> */}
              </Card>
            </section>
          </div>
        </div>
      </SafeArea>
    );
  }

}