/**
 * 121 Digital Console is a trading name of 121 Digital Services Limited
 *
 * @category    Web Application
 * @package     121 Digital Core
 * @subpackage  Backend Request
 * @author      James Gibbons <jgibbons@121digital.co.uk>
 * @license     https://www.121digital.co.uk/license 121 Digital Software License
 * @link        https://www.121digital.co.uk
 */

import Backend_Request from "../Request/BackendRequest";

/**
 * Disclaimer: This source code and its comments are the intellectual property
 * of 121 Digital Console, a trading name of 121 Digital Services Limited. It may not be used, modified,
 * or distributed without explicit permission from 121 Digital Console, a trading name of 121 Digital Services Limited.
 */

/**
 * Represents a support department.
 */
export interface Support_Department {
  /** The display name of the department. */
  display_name: string;
  
  /** The unique identifier for the department. */
  department_id: number;
};

/**
 * Represents the structure for creating a new support ticket.
 */
export interface New_Support_Ticket {
  /** The title of the support ticket. */
  title: string;
  
  /** The ID of the department the ticket is associated with. */
  department_id: number;
  
  /** The message or description of the issue. */
  message: string;
  
  /** The urgency level of the ticket (e.g., low, medium, high). */
  urgency: string;
};

/**
 * Represents a support ticket with its basic details.
 */
export interface Support_Ticket {
  /** The title of the support ticket. */
  title: string;
  
  /** The current status of the ticket (e.g., open, closed, pending). */
  status: string;
  
  /** The date the ticket was created or last updated. */
  date: any;
  
  /** The name of the department handling the ticket. */
  department_name: string;
  
  /** The unique identifier for the support ticket. */
  ticket_id: string;
  
  /** The message or description of the ticket. */
  message: string;
};

/**
 * Represents a reply to a support ticket.
 */
export interface Support_Ticket_Reply {
  /** The name of the person replying. */
  name: string;
  
  /** The email address of the respondent. */
  email: string;
  
  /** Indicates if the respondent is a staff member. */
  is_staff: string;
  
  /** The date and time the reply was posted. */
  date: string;
  
  /** The message content of the reply. */
  message: string;
};

/**
 * Represents a complete support ticket with its replies.
 */
export interface Support_Ticket_Full {
  /** The title of the support ticket. */
  title: string;
  
  /** The current status of the ticket (e.g., open, closed, pending). */
  status: string;
  
  /** The date the ticket was created or last updated. */
  date: any;
  
  /** The name of the department handling the ticket. */
  department_name: string;
  
  /** The unique identifier for the support ticket. */
  ticket_id: string;
  
  /** The message or description of the ticket. */
  message: string;
  
  /** An array of replies associated with the support ticket. */
  replies: Array<Support_Ticket_Reply>;
};

export default class Support {

  /**
   * @method reopen_ticket
   * @description Sends a request to reopen a previously closed support ticket with the given ticket_id.
   *              If the request is successful, it returns true. If the request fails or the ticket
   *              cannot be reopened, it throws an error with a relevant message.
   * @param {string} ticket_id - The ID of the ticket to be reopened.
   * @returns {Promise<boolean>} A promise that resolves to true if the ticket is successfully reopened.
   * @throws {string} Throws an error message if the ticket cannot be reopened.
   * @example
   * // Example usage:
   * const success = await Support.reopen_ticket('ticket-id');
   * if (success) {
   *   console.log('Ticket reopened successfully');
   * } else {
   *   console.log('Failed to reopen ticket');
   * }
  */
  public static async reopen_ticket(ticket_id: string) {
    try {
      const request = new Backend_Request({
        endpoint: "v2/support/ticket/reopen",
        requestBody: { ticket_id: ticket_id },
        verbose: false
      });

      const result = await request.perform();

      if (result.is_success) {
        return true;
      } else {
        throw "Cannot reopen support ticket...";
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * @method close_ticket
   * @description Sends a request to close a support ticket with the given ticket_id.
   *              If the request is successful, it returns true. If the request fails or the ticket
   *              cannot be closed, it throws an error with a relevant message.
   * @param {string} ticket_id - The ID of the ticket to be closed.
   * @returns {Promise<boolean>} A promise that resolves to true if the ticket is successfully closed.
   * @throws {string} Throws an error message if the ticket cannot be closed.
   * @example
   * // Example usage:
   * const success = await Support.close_ticket('ticket-id');
   * if (success) {
   *   console.log('Ticket closed successfully');
   * } else {
   *   console.log('Failed to close ticket');
   * }
  */
  public static async close_ticket(ticket_id: string) {
    try {
      const request = new Backend_Request({
        endpoint: "v2/support/ticket/close",
        requestBody: { ticket_id: ticket_id },
        verbose: false
      });

      const result = await request.perform();

      if (result.is_success) {
        return true;
      } else {
        throw "Cannot close support ticket...";
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * Creates a reply for a specific support ticket.
   * 
   * @param ticket_id - The unique identifier of the support ticket.
   * @param reply_message - The message content of the reply.
   * @returns A promise that resolves to `true` if the reply is successfully created.
   * @throws An error if the reply cannot be created.
   */
  public static async create_ticket_reply(ticket_id: string, reply_message: string): Promise<boolean> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/support/ticket/reply",
        requestBody: { ticket_id: ticket_id, reply_message: reply_message },
        verbose: false
      });

      const result = await request.perform();

      if (result.is_success) {
        return true;
      } else {
        throw "Cannot load support ticket...";
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * Retrieves a specific support ticket by its ID.
   * 
   * @param ticket_id - The unique identifier of the support ticket.
   * @returns A promise that resolves to the full support ticket details, including replies.
   * @throws An error if the ticket cannot be loaded.
   */
  public static async get_ticket(ticket_id: string): Promise<Support_Ticket_Full> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/support/ticket/get",
        requestBody: { ticket_id: ticket_id },
        verbose: false
      });

      const result = await request.perform();

      if (result.is_success) {
        const supportTicket = result.response_data.support_ticket;
        return {
          title:           supportTicket.title,
          status:          supportTicket.status,
          date:            supportTicket.date,
          department_name: supportTicket.department_name,
          ticket_id:       ticket_id,
          message:         supportTicket.message,
          replies:         supportTicket.replies
        } as Support_Ticket_Full;
      } else {
        throw "Cannot load support ticket...";
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * Retrieves all support tickets from the backend.
   * 
   * @returns A promise that resolves to an array of support tickets.
   * @throws An error if the tickets cannot be loaded.
   */
  public static async get_all_tickets(): Promise<Array<Support_Ticket>> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/support/tickets/get",
        requestBody: {},
        verbose: false
      });

      const result = await request.perform();

      if (result.is_success) {
        const supportTickets = result.response_data.support_tickets;
        return supportTickets as Array<Support_Ticket>;
      } else {
        throw "Cannot load support tickets...";
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * Retrieves a list of support departments from the backend.
   * 
   * @returns {Promise<Array<Support_Department>>} A promise that resolves to an array of support departments.
   * @throws {string} Throws an error if the support departments cannot be loaded.
   * 
   * @example
   * try {
   *   const departments = await get_support_departments();
   *   console.log(departments);
   * } catch (error) {
   *   console.error(error);
   * }
   */
  public static async get_support_departments(): Promise<Array<Support_Department>> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/support/departments/get",
        requestBody: {},
        verbose: false
      });
      const result = await request.perform();

      if(result.is_success) {
        const supportDepartments = result.response_data.support_departments;
        return supportDepartments as Array<Support_Department>;  

      }
      else {
        throw "Cannot load support departments...";
      }

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Creates a new support ticket by sending the provided details to the backend.
   * 
   * @param {New_Support_Ticket} newSupportTicket - The support ticket details including title, department ID, and message.
   * @returns {Promise<boolean>} A promise that resolves to `true` if the ticket is successfully created.
   * @throws {string} Throws an error if the support ticket cannot be created.
   * 
   * @example
   * try {
   *   const ticket = await create_support_ticket({
   *     title: "Login Issue",
   *     department_id: 1,
   *     message: "I am unable to log in with my credentials."
   *   });
   *   console.log("Ticket created:", ticket);
   * } catch (error) {
   *   console.error(error);
   * }
   */
  public static async create_support_ticket(newSupportTicket: New_Support_Ticket): Promise<boolean> {
    try {
      const request = new Backend_Request({
        endpoint: 'v2/support/tickets/create',
        requestBody: {
          title:         newSupportTicket.title,
          department_id: newSupportTicket.department_id,
          message:       newSupportTicket.message,
          urgency:       newSupportTicket.urgency
        },
        verbose: true
      });
      const result = await request.perform();

      if(result.is_success) {
        return true;

      }
      else {
        throw "Cannot create new support ticket.";
      }

    }
    catch(error) {
      throw error;
    }
  }

}