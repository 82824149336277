/**
 * 121 Digital Console is a trading name of 121 Digital Services Limited
 *
 * @category    Web Application
 * @package     121 Digital Core
 * @subpackage  Backend Request
 * @author      James Gibbons <jgibbons@121digital.co.uk>
 * @license     https://www.121digital.co.uk/license 121 Digital Software License
 * @link        https://www.121digital.co.uk
 */

import Backend_Request from "../Request/BackendRequest";

/**
 * Disclaimer: This source code and its comments are the intellectual property
 * of 121 Digital Console, a trading name of 121 Digital Services Limited. It may not be used, modified,
 * or distributed without explicit permission from 121 Digital Console, a trading name of 121 Digital Services Limited.
 */


/**
 * Interface for generic hosting service type;
 * 
 */
export interface Hosting_Service_Type {
  package:            string,
  primary_domain:     string,
  service_id:         number,
  availability_zone:  string,
  remote_status:      string,
  control_panel_type: string,
  date_created:       string
};

export default class Hosting {

  /**
   * Function used to get the users hosting products that have been assigned
   * to their account..
   * 
   * 
   */
  public static async get_hosting_services(): Promise<Array<Hosting_Service_Type>> {
    try {
      try {
        const request = new Backend_Request({
          endpoint: "v2/hosting/services/get",
          requestBody: {},
          verbose: false
        });
        const result = await request.perform();
        if (result.is_success) {
          console.log(result.response_data.hosting_services);
          return result.response_data.hosting_services;

        } else {
          throw "Cannot load accounts hosting services.";
        }
      } catch (error) {
        throw error;
      }

    }
    catch(error) {
      throw error;
    }
  }

  public static async get_cpanel_session_redirect_url(hostingServiceId: number): Promise<any> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/hosting/service/start_session",
        requestBody: {
          hosting_service_id: hostingServiceId
        },
        verbose: true
      });
      const result = await request.perform();
      if (result.is_success) {
        return result.response_data;

      } else {
        throw "Cannot load accounts hosting services.";
      }

    }
    catch(error) {
      throw error;
    }
  }

}