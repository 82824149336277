/**
 * 121 Digital Console is a trading name of 121 Digital Services Limited
 *
 * @category    Web Application
 * @package     121 Digital Core
 * @subpackage  Backend Request
 * @author      James Gibbons <jgibbons@121digital.co.uk>
 * @license     https://www.121digital.co.uk/license 121 Digital Software License
 * @link        https://www.121digital.co.uk
 */

/**
 * Disclaimer: This source code and its comments are the intellectual property
 * of 121 Digital Console, a trading name of 121 Digital Services Limited. It may not be used, modified,
 * or distributed without explicit permission from 121 Digital Console, a trading name of 121 Digital Services Limited.
 */

import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, DialogContent, IBreadcrumbItem, Stack } from '@fluentui/react';
import { Card, CardHeader } from '@fluentui/react-card';
import Spacer from '../../components/Spacer/Spacer';
import { Button, Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle, DrawerBody, DrawerHeader, DrawerHeaderTitle, Field, OverlayDrawer, Spinner, Table, TableCell, TableRow, Text } from '@fluentui/react-components';
import Support, { Support_Ticket_Reply } from '../../lib/Support/Support';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import { Editor } from '@tinymce/tinymce-react';
import DOMPurify from "dompurify";
import env from '../../env/env';
import { Dismiss24Regular, ThumbLike16Regular, ThumbLike24Regular } from '@fluentui/react-icons';
import Global_Error from '../../lib/GlobalError/GlobalError';
import SupportCloseTicketDialog from '../../components/Support/SupportCloseTicketDialog/SupportCloseTicketDialog';

export default class ViewSupportTicket_View extends React.Component {

  /**
   * Stores the breadcrumb navigation items for the support ticket page.
   * Used to provide navigation links for users.
   */
  private breadcrumbItems: IBreadcrumbItem[];

  /**
   * Holds the component's styling properties.
   * Can be extended to store various CSS styles for the component.
   */
  private styles: any = {};

  /**
   * Stores the support ticket ID retrieved from the URL.
   * Used to load the relevant support ticket data.
   */
  private ticket_id: string = "";

  /**
   * Initializes the component state, breadcrumb navigation, and retrieves 
   * the support ticket ID from the URL.
   * 
   * This constructor sets up the component's breadcrumb navigation, extracts 
   * the ticket ID from the URL, and loads the corresponding support ticket data. 
   * If no ticket ID is found, it redirects the user to the support page.
   * 
   * It also defines styles and initializes the component's base state.
   * 
   * @param props - Component properties.
  */
  public constructor(props: any) {
    super(props);

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate("/") },
      { text: 'Customer Support', key: 'customer_support', onClick: () => (this.props as any).navigate('/support')},
      { text: 'View Support Ticket', key: 'view_customer_support_ticket', isCurrentItem: true }
    ];
    

    // validate the URL parameters...
    const currentUrl = window.location.href;
    // Find the part after '/order/'
    const ticketId = currentUrl.split('/view-ticket/')[1];
    if (typeof ticketId !== 'undefined') {
      // this.setState({
      //   support_ticket_id: ticketId
      // });
      this.ticket_id = ticketId;
      this.load_data();
    } else {
      // we do not have an access code, so we will display an invalid error....
      (this.props as any).navigate('/support');
    }
    this.styles = {
      flex_wrapper: {
        width: '100%',
        display: 'flex',
        marginLeft: 1,
        gap: 15
      }
    };

    // define the base state...
    this.state = {
      is_loading: true,
      support_ticket_id: ticketId,
      display_post_reply: false,

      new_reply: "",
      new_reply_posting: false,
      currentPage: 2,

      display_close_ticket_dialog: false
    };
  }

  /**
   * Lifecycle method that runs after the component is mounted.
   * 
   * This method is currently empty but can be used to trigger 
   * additional actions when the component loads, such as 
   * fetching data, setting up event listeners, or initializing state.
  */
  public componentDidMount(): void {

  }

  /**
   * Loads the support ticket data and updates the component state.
   * 
   * This function fetches a support ticket by its ID and determines if 
   * pagination is needed based on the number of replies. If successful, 
   * it updates the component state with the ticket details. If an error 
   * occurs, it displays a user-friendly error message.
   * 
   * @returns A promise that resolves when the data is loaded.
  */
  public async load_data(): Promise<void> {
    try {
      const supportTicket = await Support.get_ticket(this.ticket_id);

      // does the support ticket have more than 3 replies, to add pages for...
      let currentPage = 1;
      const repliesPerPage = 2;
      const totalReplies = supportTicket.replies.length;
      let hasRepliesPages = false;
      
      if (totalReplies > repliesPerPage+1) {
        hasRepliesPages = true;
        currentPage = Math.ceil(totalReplies / repliesPerPage); // Set to the last page
      }

      this.setState({
        is_loading: false,
        support_ticket_id: this.ticket_id,
        support_ticket: supportTicket,
        has_replies_pages: hasRepliesPages, 
        currentPage: currentPage
      });

    }
    catch(error) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Cannot Load Support Ticket",
        error_content: "There seems to be an issue loading this support ticket. Please refresh the page and try again or contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
      });
    }
  }

  /**
   * Posts a reply to the current support ticket.
   * 
   * This function retrieves the new reply message from the component state, 
   * disables UI interactions while posting, sends the reply to the backend, 
   * and then reloads the support ticket data. If an error occurs, it displays 
   * a user-friendly error message.
   * 
   * @returns A promise that resolves when the reply is successfully posted.
  */
  public async post_reply(): Promise<void> {
    try {
      const state: any = (this.state as any);
      const replyMessage: string = state.new_reply;

      // disable UI while posting...
      this.setState({
        new_reply_posting: true
      });

      await Support.create_ticket_reply(state.support_ticket_id, replyMessage);
      
      // reload the support ticket data and close the dialog..
      this.setState({
        is_loading: true,
        display_post_reply: false,
        new_reply_posting: false
      });
      this.load_data();
    }
    catch(error) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Cannot Post Ticket Reply",
        error_content: "There seems to be an issue posting this support ticket reply. Please refresh the page and try again or contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
      });
    }
  }

  /**
   * @method close_ticket
   * @description Closes a support ticket identified by the support_ticket_id in the component's state.
   *              It sets a loading state while attempting to close the ticket and handles any errors
   *              that may occur during the process by displaying a user-visible error message.
   * @returns {Promise<void>} A promise that resolves when the ticket is successfully closed.
   * @throws {Error} Throws an error if the ticket cannot be closed, which is caught and handled internally.
   * @example
   * // Example usage:
   * await this.close_ticket();
   */
  private close_ticket = async (): Promise<void> => {
    try {
      const state:any = (this.state as any);
      const supportTicketId: string = state.support_ticket_id;
      this.setState({
        is_loading: true,
        display_close_ticket_dialog: false
      });

      await Support.close_ticket(supportTicketId);
      this.load_data();

    }
    catch(error) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Cannot Close Support Ticket",
        error_content: "There seems to be an issue closing this support ticket reply. Please refresh the page and try again or contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
      });
    }
  }

  /**
   * @method reopen_ticket
   * @description Reopens a previously closed support ticket. This method updates the state 
   *              to reflect the reopening of the ticket and handles any necessary loading 
   *              indicators or user feedback.
   * @returns {Promise<void>} A promise that resolves when the ticket has been successfully reopened.
   * @throws {Error} Throws an error if the reopening process fails, which should be handled accordingly.
   * @example
   * // Example usage:
   * await this.reopen_ticket();
   */
  private reopen_ticket = async (): Promise<void> => {
    try {
      const state:any = (this.state as any);
      const supportTicketId: string = state.support_ticket_id;
      this.setState({
        is_loading: true
      });

      await Support.reopen_ticket(supportTicketId);
      this.load_data();

    }
    catch(error) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Cannot Reopen Support Ticket",
        error_content: "There seems to be an issue reopening this support ticket reply. Please refresh the page and try again or contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
      });
    }
  }

  private get_page_selector(): JSX.Element {
    const state = this.state as any;
    const repliesPerPage = 2;
    const replies = state.support_ticket.replies;
    const pagesCount = Math.ceil(replies.length / repliesPerPage);

    if(!state.has_replies_pages) {
      return (<></>);
    }
  
    return (
      <Stack horizontal>
        <Button disabled={state.currentPage === 1} onClick={() => this.setState({ currentPage: 1 })}>
          First Page
        </Button>
  
        {Array.from({ length: pagesCount }, (_, index) => (
          <Button
            key={index}
            disabled={state.currentPage === index + 1}
            onClick={() => this.setState({ currentPage: index + 1 })}
          >
            {index + 1}
          </Button>
        ))}
  
        <Button
          disabled={state.currentPage === pagesCount}
          onClick={() => this.setState({ currentPage: pagesCount })}
        >
          Last Page
        </Button>
      </Stack>
    );
  }

  private get_current_page_replies(): any {
    const state = this.state as any;
    if(!state.has_replies_pages) {
      // just return all the replies, since we do not have multiple replies on this page...
      return (
        <div>
        {state.support_ticket.replies.map((reply: Support_Ticket_Reply) => {
          if(reply.is_staff) {
            return (
              <Card>
                <CardHeader header={
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "50%" }}>
                      <Text style={{ color: "#ff5733", textAlign: "left", display: "block" }} weight="semibold">
                        Reply from {reply.name} (121 Digital Staff)
                      </Text>
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <Text style={{ textAlign: 'right', display: "block" }}>{reply.date}</Text>
                    </div>
                  </div>
                } />
                  <div dangerouslySetInnerHTML={{
                    __html: reply.message
                  }}></div>
                  <Button style={{width: 150}} onClick={() => {
                    this.setState({
                      display_post_reply: true
                    });
                  }}>Reply</Button>
              </Card>
            );
          }
          else {
            return (
              <Card>
                <CardHeader header={
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "50%" }}>
                      <Text style={{textAlign: "left", display: "block" }} weight="semibold">
                        Reply from {reply.name}
                      </Text>
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <Text style={{ textAlign: 'right', display: "block" }}>{reply.date}</Text>
                    </div>
                  </div>
                } />
                  <div dangerouslySetInnerHTML={{
                    __html: reply.message
                  }}></div>
              </Card>
            );
          }
        })}
        </div>
      );
    }
    else {
      const repliesPerPage = 2;
      let replies = state.support_ticket.replies;
      const totalPages = Math.ceil(replies.length / repliesPerPage);
      
      // Ensure currentPage is within bounds
      const currentPage = state.currentPage || totalPages;
      
      // Calculate the start and end indexes for slicing
      const startIndex = (currentPage - 1) * repliesPerPage;
      const endIndex = startIndex + repliesPerPage;
      
      // Get the replies for the current page
      const visibleReplies = replies.slice(startIndex, endIndex);
      replies = visibleReplies;

      return (
        <div>
        {replies.map((reply: Support_Ticket_Reply) => {
          if(reply.is_staff) {
            return (
              <Card>
                <CardHeader header={
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "50%" }}>
                      <Text style={{ color: "#ff5733", textAlign: "left", display: "block" }} weight="semibold">
                        Reply from {reply.name} (121 Digital Staff)
                      </Text>
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <Text style={{ textAlign: 'right', display: "block" }}>{reply.date}</Text>
                    </div>
                  </div>
                } />
                  <div dangerouslySetInnerHTML={{
                    __html: reply.message
                  }}></div>
                  <Button style={{width: 150}} onClick={() => {
                    this.setState({
                      display_post_reply: true
                    });
                  }}>Reply</Button>
              </Card>
            );
          }
          else {
            return (
              <Card>
                <CardHeader header={
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "50%" }}>
                      <Text style={{textAlign: "left", display: "block" }} weight="semibold">
                        Reply from {reply.name}
                      </Text>
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <Text style={{ textAlign: 'right', display: "block" }}>{reply.date}</Text>
                    </div>
                  </div>
                } />
                  <div dangerouslySetInnerHTML={{
                    __html: reply.message
                  }}></div>
              </Card>
            );
          }
        })}
        </div>
      );
    }
  }

  /**
   * Renders the support ticket page UI.
   * 
   * This method is responsible for displaying the support ticket details, 
   * including the ticket messages, replies, and any relevant actions 
   * such as posting a reply. It also handles loading states and UI interactions.
   * 
   * @returns The JSX elements to render the support ticket interface.
  */
  public render() {
    const state: any = this.state as any;
    return (
      <SafeArea>
        <SupportCloseTicketDialog 
          display={state.display_close_ticket_dialog} 
          onClose={(confirmClose: boolean) => {
            if(confirmClose) {
              this.close_ticket();
            }
            else {
              this.setState({
                display_cket_dialog: false
              });
            }
          }}
        />
        
        <div style={{width: "100%"}}>
          <Breadcrumb
            style={{marginLeft: -6}}
            items={this.breadcrumbItems}
            maxDisplayedItems={5}
            ariaLabel="Breadcrumb with static width"
            overflowAriaLabel="More links"
          />
          <h1>View Support Ticket ({state.support_ticket_id})</h1>
        </div>

        <Spacer size='25px' />

        {state.is_loading? (
          <ViewLoader message='Loading support ticket. Please wait..' />
        ): (
          <div style={this.styles.flex_wrapper}>
            <div style={{width: 400}}>
              <Card>
                <CardHeader
                  header={<Text weight="semibold">Ticket Overview</Text>} 
                />

                <div style={this.styles.flex_wrapper}>
                  <div style={{width: "100%", display: 'flex', gap: 5}}>
                    {(state.support_ticket.status == "ticket_open")? (
                      <Button appearance='primary' onClick={() => this.setState({display_close_ticket_dialog: true})}>
                        Mark Resolved
                      </Button>
                    ) : (
                      <Button appearance='primary' onClick={this.reopen_ticket}>
                        Reopen Ticket
                      </Button>
                    )}

                    <Button onClick={() => {
                      this.setState({
                        display_post_reply: true
                      });
                    }}>Add Reply</Button>
                  </div>
                </div>

                <Table>
                  <TableRow>
                    <TableCell>Ticket Status</TableCell>
                    <TableCell>Ticket Open</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date Created</TableCell>
                    <TableCell>{state.support_ticket.date}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Assigned Department</TableCell>
                    <TableCell>{state.support_ticket.department_name}</TableCell>
                  </TableRow>
                </Table>
              </Card>
            </div>
            <div style={{width: 800}}>
              <Card>
                <CardHeader 
                  header={<Text weight="semibold">Ticket Thread</Text>} 
                />

                <b>Subject</b>
                {state.support_ticket.title}

                <b>Original Message</b>
                <div dangerouslySetInnerHTML={{
                  __html: state.support_ticket.message
                }}></div>
              </Card>

              {this.get_current_page_replies()}
              {this.get_page_selector()}

              <Spacer size='55px' />
            </div>
          </div>
        )}

      <OverlayDrawer
          size={'medium'}
          position="end"
          open={state.display_post_reply}
          // onOpenChange={}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => {
                    this.setState({
                      display_post_reply: false
                    });
                  }}
                />
              }
            >
              Post Ticket Reply
            </DrawerHeaderTitle>
          </DrawerHeader>

          <DrawerBody>
            <Spacer size='25px' />

            <Field label={"Message"}>
              <Editor
                value={state.new_reply}
                init={{
                  apiKey: env.TINYMCE_API_KEY,
                  menubar: false,
                  width: "100%", // Ensure TinyMCE itself is full width
                }}
                apiKey={env.TINYMCE_API_KEY}
                onEditorChange={(content) => {  
                  this.setState({
                    new_reply: content
                  });
                }}
              />
            </Field>


            <Spacer size='25px' />
            <div style={{display: 'flex', gap: 5}}>
              <Button disabled={state.new_reply_posting} appearance='primary' onClick={() => {
                this.post_reply();
              }}>
                {state.new_reply_posting? (
                  <Spinner size='tiny' />
                ): (
                  "Post Reply"
                )}
              </Button>
              <Button onClick={() => {
                this.setState({
                  display_post_reply: false
                });
              }}>Close</Button>
            </div>

          </DrawerBody>
        </OverlayDrawer>

      </SafeArea>
    );
  }

}