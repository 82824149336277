import React from 'react';
import Authentication_Service from './../../lib/Authentication/AuthenticationService';

export default class ProtectedRoute extends React.Component {

  public constructor(props: any) {
    super(props);

    // get the initial auth state...
    const isAuthenticated = Authentication_Service.is_authenticated();

    // check that we are authenticated...
    if(!isAuthenticated) {
      window.location.assign("/auth/login");
    }
  }

  public componentDidMount(): void {
    
  }

  public render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }

}