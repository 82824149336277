import React, { useState } from 'react';
import { Nav, INavLinkGroup, INavLink } from '@fluentui/react/lib/Nav';
import { Stack } from '@fluentui/react/lib/Stack';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Icon } from '@fluentui/react/lib/Icon'; // Import Icon from Fluent UI
import Authentication_Service from '../../lib/Authentication/AuthenticationService';
import { Capabilities, Capability_Types } from '../../lib/Capabilities/Capabilities';

import {ReactComponent as BrandLogoSvg} from './../../assets/branding/brand.svg';
import Spacer from '../Spacer/Spacer';

initializeIcons();

interface IExpandedItems {
  [key: string]: boolean;
}

const do_navigate = (url: string) => {

}

const get_sidebar_items = (): INavLinkGroup[] => {
  const sidebarItems: INavLinkGroup[] = [
    {
      links: [
        {
          name: 'Dashboard Home',
          url: '/',
          key: 'home',
          icon: 'Home', // Use icon names directly
        },
        // 
        // {
        //   name: 'Email',
        //   url: '/documents',
        //   key: 'email',
        //   icon: 'Mail',
        //   links: [
        //     {
        //       name: 'Your Websites',
        //       url: '/documents/reports',
        //       key: 'reports',
        //       icon: 'ReportDocument',
        //     },
        //     {
        //       name: 'Website Statistics',
        //       url: '/documents/invoices',
        //       key: 'invoices',
        //       icon: 'Money',
        //     },
        //   ],
        // },
        // {
        //   name: 'SMS',
        //   url: '/documents',
        //   key: 'sms',
        //   icon: 'Message',
        //   links: [
        //     {
        //       name: 'Your Websites',
        //       url: '/documents/reports',
        //       key: 'reports',
        //       icon: 'ReportDocument',
        //     },
        //     {
        //       name: 'Website Statistics',
        //       url: '/documents/invoices',
        //       key: 'invoices',
        //       icon: 'Money',
        //     },
        //   ],
        // },
        // {
        //   name: 'AI Content Writer',
        //   url: '/documents',
        //   key: 'ai_content',
        //   icon: 'Robot',
        //   links: [
        //     {
        //       name: 'Your Websites',
        //       url: '/documents/reports',
        //       key: 'reports',
        //       icon: 'ReportDocument',
        //     },
        //     {
        //       name: 'Website Statistics',
        //       url: '/documents/invoices',
        //       key: 'invoices',
        //       icon: 'Money',
        //     },
        //   ],
        // },
        // {
        //   name: 'Billing',
        //   url: '/documents',
        //   key: 'billing',
        //   icon: 'Page',
        //   links: [
        //     {
        //       name: 'Invoices',
        //       url: '/documents/reports',
        //       key: 'reports',
        //       icon: 'ReportDocument',
        //     },
        //     {
        //       name: 'Payment Methods',
        //       url: '/documents/invoices',
        //       key: 'invoices',
        //       icon: 'Money',
        //     },
        //     {
        //       name: 'Cost Calculator',
        //       url: '/documents/invoices',
        //       key: 'invoices',
        //       icon: 'Money',
        //     },
        //   ],
        // },
        // {
        //   name: 'Settings',
        //   url: '/settings',
        //   key: 'settings',
        //   icon: 'Settings',
        //   links: [
        //     {
        //       name: 'Profile',
        //       url: '/settings/profile',
        //       key: 'profile',
        //       icon: 'Contact',
        //     },
        //     {
        //       name: 'Security',
        //       url: '/settings/security',
        //       key: 'security',
        //       icon: 'Lock',
        //     },
        //   ],
        // },
        // {
        //   name: 'Help',
        //   url: '/help',
        //   key: 'help',
        //   icon: 'Help',
        // },
      ],
    }
  ];

  // define dynamic sidebar items based on the user capabilities...
  if(Capabilities.has_capability(Capability_Types.websites)) {
    sidebarItems[0].links.push({
        name: 'Websites',
        url: '/documents',
        key: 'documents',
        icon: 'Globe',
        links: [
          {
            name: 'Your Websites',
            url: '/documents/reports',
            key: 'reports',
            icon: 'ReportDocument',
          },
          {
            name: 'Website Statistics',
            url: '/documents/invoices',
            key: 'invoices',
            icon: 'Money',
          },
        ],
      });
  }

    // define dynamic sidebar items based on the user capabilities...
    if(Capabilities.has_capability(Capability_Types.billing)) {
      sidebarItems[0].links.push(        
        {
          name: 'Billing',
          url: '/documents',
          key: 'billing',
          icon: 'Page',
          links: [
            {
              name: 'Invoices',
              url: '/documents/reports',
              key: 'reports',
              icon: 'ReportDocument',
            },
            {
              name: 'Payment Methods',
              url: '/documents/invoices',
              key: 'invoices',
              icon: 'Money',
            },
            {
              name: 'Cost Calculator',
              url: '/documents/invoices',
              key: 'invoices',
              icon: 'Money',
            },
          ],
        });
    }

    // define dynamic sidebar items based on the user capabilities...
    if(Capabilities.has_capability(Capability_Types.support)) {
      sidebarItems[0].links.push(        
        {
          name: 'Support',
          url: '/support',
          key: 'support',
          icon: 'Page',
          links: [
            {
              name: 'Support Overview',
              url: '/support',
              key: 'support_overview',
              icon: 'ReportDocument',
            },
            {
              name: 'Payment Methods',
              url: '/documents/invoices',
              key: 'invoices',
              icon: 'Money',
            },
            {
              name: 'Cost Calculator',
              url: '/documents/invoices',
              key: 'invoices',
              icon: 'Money',
            },
          ],
        });
    }

    // tmp add the domains...
    sidebarItems[0].links.push({
      name: 'Domains',
      url: '/domains',
      key: 'domains',
      icon: 'Globe',
      links: [
        {
          name: 'Your Domains',
          url: '/documents/reports',
          key: 'reports',
          icon: 'ReportDocument',
        },
        {
          name: 'Register Domain',
          url: '/documents/invoices',
          key: 'invoices',
          icon: 'Plus',
        },
      ],
    });

  return sidebarItems;
}

const styles = {
  nav: {
    // position: "absolute",
    // left: 0,
    // top: 55
    marginTop: 55
  },
  nav_wrapper: { 
    width: 250, 
    height: '100vh', 
    marginTop: 0, 
    // paddingTop: 15,
    position: 'fixed',
    backgroundColor: "white",
    // boxSizing: 'border-box', 
    border: '1px solid #eee', 
    overflowY: 'auto', 
    // paddingLeft: 10, 
    zIndex: 99999
  },
  logo_wrapper: {
    height: 58, 
    paddingTop: 10, 
    paddingLeft: 10, 
    width: "100%", 
    // borderLeft: '1px solid #eee',
    borderBottom: '1px solid #eee'
  }
}

const Sidebar: React.FC = () => {
  const [expandedItems, setExpandedItems] = useState<IExpandedItems>({});
  const shouldSidebarDisplay:boolean = Authentication_Service.is_authenticated();

  // tmp, we will hide the sidebar in the new UI...
  // const shouldSidebarDisplay = true;

  const onLinkClick = (ev: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    ev.preventDefault();
    if (item && item.key) {
      setExpandedItems(prevState => ({
        ...prevState,
        [(item as any).key]: !prevState[(item as any).key],
      }));
    }
  };

  const renderNavLink = (link: INavLink, isExpanded: boolean) => (
    <div key={link.key}>
      <div onClick={(ev) => onLinkClick(ev, link)} style={{ cursor: 'pointer', padding: '10px' }}>
        {link.icon && <Icon iconName={link.icon} />} {/* Use Fluent UI Icon component */}
        <span style={{ marginLeft: 10 }}>{link.name}</span>
      </div>
      {isExpanded && link.links && (
        <div style={{ paddingLeft: 20 }}>
          {link.links.map(subLink => subLink.key ? renderNavLink(subLink, !!expandedItems[subLink.key]) : null)}
        </div>
      )}
    </div>
  );

  if(shouldSidebarDisplay) {
    return (
      <div style={styles.nav_wrapper as any}>
        <div style={styles.logo_wrapper}>
          <BrandLogoSvg height={40} style={{height: 45, marginLeft: -370}}/>
        </div>

        <Spacer size='25px' />

        <Stack style={{paddingLeft: 10}} horizontal tokens={{ childrenGap: 10 }}>
          <Stack.Item grow>
            <nav style={{}}>
              {get_sidebar_items()[0].links.map(link => renderNavLink(link, !!expandedItems[(link as any).key]))}
            </nav>
          </Stack.Item>
          <Stack.Item grow>
            {/* Main content goes here */}
          </Stack.Item>
        </Stack>
      </div>
    );
  }
  else {
    return (
      <div />
    );
  }
};

export default Sidebar;
