import { DefaultButton, IContextualMenuItem, IContextualMenuProps, Persona, PersonaSize } from '@fluentui/react';
import React from 'react';
import Authentication_Service from '../../lib/Authentication/AuthenticationService';

export interface HeaderProfile_Props {
  first_name: string,
  last_name: string,

  onNavigateOut: any
};

export default class HeaderProfile extends React.Component <HeaderProfile_Props> {

  private menuProps: IContextualMenuProps;
  private menuItems: IContextualMenuItem[];

  constructor(props: any) {
    super(props);

     this.menuItems = [
      {
        key: 'profile',
        text: 'Account Settings',
        onClick: () => this.props.onNavigateOut("/account")
      },
      {
        key: 'support',
        text: 'Customer Support',
        onClick: () => this.props.onNavigateOut("/support")
      },
      {
        key: 'restart',
        text: 'Restart Session',
        onClick: () => window.location.assign(window.location.href)
      },
      {
        key: 'logout',
        text: 'Logout',
        onClick: async () => {
          await Authentication_Service.destroy_session_redirect();
        }
      }
    ];

    this.menuProps = {
      items: this.menuItems,
      onDismiss: () => {}
    };
  }

  public render() {
    const state = this.state as any;

    return (
      <div>
        <DefaultButton
          onClick={()=> {}}
          menuProps={this.menuProps}
          menuIconProps={{ iconName: 'ChevronDown' }}
          style={{
            backgroundColor: "transparent",
            borderStyle: "none"
          }}
        >
          <Persona
            text={this.props.first_name + " " + this.props.last_name}
            size={PersonaSize.size32}
          />
        </DefaultButton>
      </div>
    );
  }

}