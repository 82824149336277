/**
 * 121 Digital Console is a trading name of 121 Digital Services Limited
 *
 * @category    Web Application
 * @package     121 Digital Core
 * @subpackage  Backend Request
 * @author      James Gibbons <jgibbons@121digital.co.uk>
 * @license     https://www.121digital.co.uk/license 121 Digital Software License
 * @link        https://www.121digital.co.uk
 */

import Backend_Request from "../Request/BackendRequest";

/**
 * Disclaimer: This source code and its comments are the intellectual property
 * of 121 Digital Console, a trading name of 121 Digital Services Limited. It may not be used, modified,
 * or distributed without explicit permission from 121 Digital Console, a trading name of 121 Digital Services Limited.
 */

export interface Generic_Domain_Name {
  domain_name:  string,
  order_date:   string,
  is_cancelled: boolean,
  renew_date:   string
};

export default class Domain_Name {

  public static async get_domain_names(): Promise<void> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/domains/get",
        requestBody: { },
        verbose: false
      });

      const result = await request.perform();

      if (result.is_success) {
        return result.response_data.domain_orders;
      } else {
        throw "Cannot get domain names...";
      }

    }
    catch(error) {
      throw error;
    }
  }

}