import React from 'react';
import Authentication_Service from '../../lib/Authentication/AuthenticationService';
import { Text } from '@fluentui/react-components';
import BlockedFeature from '../BlockedFeature/BlockedFeature';

export interface SafeArea_Props {
  fullscreen?: boolean
}

export default class SafeArea extends React.Component <SafeArea_Props> {

  private styles: any = {};

  constructor(props: any) {
    super(props);

    if(!this.props.fullscreen) {
      this.styles = {
        position: "absolute",
        top: 55,
        left: 288,
        // Sidebar is gone in the new UI, so no left offset is required..


        // left: 55, // generic 25px margin..
        right: 55,

        width: "calc(100% - 0px - 35px - 0px - 288px)",
        height: "calc(100% - 50px)",
        overflow: "scroll",
        paddingRight: "25px",
        paddingTop: 15,

        overflowX: "hidden",
      };

    }
    else {
      this.styles = {
        position: "absolute",
        top: 55,
        left: 0,
        width: "calc(100% - 50px)",
        height: "calc(100% - 50px)",
        overflow: "scroll",
        paddingRight: 60,
        paddingTop: 15,
        paddingLeft: 25,

        zIndex: 99,
        backgroundColor: "white"
      };
    }

    const authenticated = Authentication_Service.is_authenticated();
    this.state = {
      authenticated: authenticated
    }
  }

  public render() {
    const state = this.state as any;

    if(state.authenticated) {
      return (
        <div style={this.styles}>
          {this.props.children}
        </div>
      );
    }
    else {
      return (
        <div style={{margin: 25}}>
          <BlockedFeature logoutButton={true}/>
        </div>
      );
    }
  }

}