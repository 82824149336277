import { Stack, StackItem } from '@fluentui/react';
import { Spinner } from '@fluentui/react-components';
import React from 'react';

export interface ViewLoader_Props {
  message: string,
  height?: string,
  marginLeft?: string
};

export default class ViewLoader extends React.Component <ViewLoader_Props> {

  public constructor(props: any) {
    super(props);

    let height = "100vh";
    let marginLeft = "0px";
    if(this.props.height) {
      height = this.props.height;
    }
    if(this.props.marginLeft) {
      marginLeft = this.props.marginLeft;
    }


    this.state = {
      height,
      marginLeft
    };
  }

  public render() {
    const state = this.state as any;

    return (
      <Stack
      style={{marginLeft: state.marginLeft}}
      horizontalAlign="center"
      verticalAlign="center"
      styles={{ root: { height: state.height, width: '100%' } }}>
        <StackItem>
          <Spinner />
          <h4><b>{this.props.message}</b></h4>
        </StackItem>
      </Stack>
    );
  }

}