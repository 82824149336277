import { Button, Dialog, DialogActions, DialogContent, DialogSurface, DialogTitle } from '@fluentui/react-components';
import React from 'react';

export interface SupportCloseTicketDialog_Props {
  onClose: any,
  display: boolean
};

export default class SupportCloseTicketDialog extends React.Component <SupportCloseTicketDialog_Props> {

  public constructor(props: any) {
    super(props);
  }

  private close_dialog = (confirmation: boolean) => {
    if(typeof this.props.onClose !== "undefined") {
      this.props.onClose(confirmation);
    }
  }

  public render() {
    return (
      <Dialog open={this.props.display}>
        <DialogSurface>
          <DialogTitle>Confirm Ticket Resolution</DialogTitle>
          <br />

          <DialogContent>
            This will close the support ticket and our support team will consider the issue resolved.
            You can reopen a support ticket at any time.

            <br /><br />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.close_dialog(false)}
              aria-label="Close dialog"
            >
              Close
            </Button>
            <Button
              appearance="primary"
              onClick={() => this.close_dialog(true)}
              aria-label="Close ticket and resolve"
            >
              Close Ticket
            </Button>
          </DialogActions>
        </DialogSurface>
      </Dialog>
    );
  }

}