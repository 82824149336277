/**
 * 121 Digital Console is a trading name of 121 Digital Services Limited
 *
 * @category    Web Application
 * @package     121 Digital Core
 * @subpackage  Backend Request
 * @author      James Gibbons <jgibbons@121digital.co.uk>
 * @license     https://www.121digital.co.uk/license 121 Digital Software License
 * @link        https://www.121digital.co.uk
 */

import Backend_Request from "../Request/BackendRequest";

/**
 * Interface representing the response structure for a guest order flow.
*/
export interface Guest_Order_Flow_Response {
  order_flow: {},
  website_order_flow: {}
};

/**
 * Interface representing the response structure for a guest order flow payment.
*/
export interface Guest_Order_Flow_Payment_Response {
  is_success: boolean;
  next_action_required: boolean;
  pi_client_secret: string | null;
  payment_error_reason: string | null;
}

export default class Guest_Order_Flow {

  public static async create_payment_intent(access_code: string, name_on_card: string, pm_id: string): Promise<any> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/guest/order-flow/create-payment-intent",
        authenticated: false,
        requestBody: {
          access_code:  access_code,
          name_on_card: name_on_card,
          pm_id:        pm_id
        },
        verbose: false
      });
      const result = await request.perform();
      if (result.is_success) {
        if(result.response_data.payment_intent_created) {
          return {
            payment_intent_created: true,
            payment_intent_client_secret: result.response_data.paymnt_intent_client_secret
          };
        }
        else {
          return {
            payment_intent_created: false
          };
        }

      }
      else {
        throw "Could not create payment intent...";
      }

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Validates the provided access code using the backend endpoint.
   *
   * This method sends a request to the backend service to validate an access code
   * for order flow. It expects a `boolean` response indicating whether the access
   * code is valid.
   *
   * @param {string} accessCode - The access code to validate.
   * @returns {Promise<boolean>} - A promise that resolves to `true` if the access
   * code is valid, otherwise `false`.
   * @throws {Error} - Throws an error if the request fails.
   * 
  */
  public static async validate_access_code(accessCode: string): Promise<boolean> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/guest/order-flow/validate",
        authenticated: false,
        requestBody: {
          access_code: accessCode
        },
        verbose: false
      });
      const result = await request.perform();
      if (result.is_success) {
        if(typeof result.response_data !== 'undefined' && result.response_data.access_code_valid) {

          // additionally also confirm that the access code has not already been used (the invoice is not already paid...)
          if(!result.response_data.order_flow_completed) {
            return true;
          }
          else {
            return false;
          }

        }
        else {
          return false;
        }

      } else {
        return false;
      }

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Creates a Payment Setup Intent and retrieves the client secret using the provided access code.
   *
   * This method sends a request to the backend to create a payment setup intent for the order flow.
   * It returns the client secret if the payment intent is successfully created.
   *
   * @param {string} accessCode - The access code used to create the payment setup intent.
   * @returns {Promise<any>} - A promise that resolves to the client secret string if the payment intent is created,
   * or `false` if unsuccessful.
   * @throws {Error} - Throws an error if the request fails.
   * 
  */
  public static async create_payment_setup_intent_client_id(accessCode: string): Promise<any> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/guest/order-flow/create-payment-setup-intent",
        authenticated: false,
        requestBody: {
          access_code: accessCode
        },
        verbose: false
      });
      const result = await request.perform();
      if (result.is_success) {
        if(result.response_data.payment_intent_created) {
          return result.response_data.client_secret;
        }
        else {
          return false;
        }
      } else {
        return false;
      }
    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Function used to get the order flow from the backend using the provided
   * order flow access code, usually passed into the view through the URL.
   * 
   * @return Object The guest order flow data from the backend...
   * 
   */
  public static async get_by_access_code(accessCode: string): Promise<Guest_Order_Flow_Response> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/guest/order-flow/get",
        authenticated: false,
        requestBody: {
          access_code: accessCode
        },
        verbose: false
      });
      const result = await request.perform();

      if (result.is_success) {
        console.log(result);
        return result.response_data as Guest_Order_Flow_Response;

      } else {
        throw "Cannot load guest order flow...";
      }
    }
    catch(error) {
      throw error;
    }
  }

  public static async confirm_3ds_order_flow(access_code: string, paymentIntentId: string) {
    try {
      const request = new Backend_Request({
        endpoint: "v2/guest/order-flow/confirm-3ds-payment",
        authenticated: false,
        requestBody: {
          access_code:  access_code,
          payment_intent_id: paymentIntentId
        },
        verbose: false
      });
      const result = await request.perform();

      if (result.is_success) {
        if(result.response_data.flow_success) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    catch(error) {
      throw error;
    } 
  } 

  public static async pay_guest_order_flow(access_code: string, name_on_card: string, pm_id: string): Promise<Guest_Order_Flow_Payment_Response | undefined> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/guest/order-flow/pay",
        authenticated: false,
        requestBody: {
          access_code:  access_code,
          name_on_card: name_on_card,
          pm_id:        pm_id
        },
        verbose: false
      });
      const result = await request.perform();

      if (result.is_success) {
        console.log(result);

        if(result.response_data.flow_success) {
          return {
            is_success: true,
            next_action_required: false,
            pi_client_secret: null,
            payment_error_reason: null
          };
        }
        else if(result.response_data.next_action_required) {
          return {
            is_success: false,
            next_action_required: true,
            pi_client_secret: result.response_data.pi_client_secret,
            payment_error_reason: null
          };
        }
        else {
          return {
            is_success: false,
            next_action_required: false,
            pi_client_secret: null,
            payment_error_reason: result.response_data.reason
          }
        }
        // return result.response_data as Guest_Order_Flow_Response;

      } else {
        return {
          is_success: false,
          next_action_required: false,
          pi_client_secret: null,
          payment_error_reason: "A system error has been reported. Contact 121 Digital Support."
        }
      }

    }
    catch(error) {
      throw error;
    }
  } 

}